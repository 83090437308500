import { PingDigilocker as pingDigilocker } from 'api/Kyc/pingDigilocker';
import LoadingScreen from 'components/shared/LoadingScreen';
import React, { useEffect } from 'react';

const Digilocker: React.FC = () => {
    useEffect(() => {

        const urlStr = window.location.href
        const url = new URL(urlStr)
        const refId = url.searchParams.get("state")
        const error = url.searchParams.get("error")

        if (!refId) {
            return
        }
        let digilockerStatus = error ? "REJECTED" : "APPROVED"

        const runAsync = async () => {
            const status = await pingDigilocker(refId, digilockerStatus)
            const url = window.sessionStorage.getItem('request_url')
            window.location.href = url!

        }

        runAsync()
    }, []);

    return (
        <LoadingScreen />
    );
};

export default Digilocker;  