import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Typography } from "@mui/material";
import {
  getOnRampTransaction,
  getOnRampWithdrawTransaction,
} from "api/onRampHooks";
import infoIcon from "assets/svgs/info-icon.svg";
import PendingIcon from "assets/svgs/onrampdelayed.svg";
import FailedIcon from "assets/svgs/onrampfailed.svg";
import ProcessingIcon from "assets/svgs/onrampprocessing.svg";
import SuccessIcon from "assets/svgs/onrampsuccess.svg";
import rightArrow from "assets/svgs/right-arrow.svg";
import { plainToInstance } from "class-transformer";
import { LoadingView } from "components/shared/LoadingScreen";
import MRow from "components/shared/MRow";
import { ConfigContext } from "context/ConfigContext";
import moment from "moment";
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import colors from "styles/colors";
import { TransactionStatus as Status, Transaction } from "types/transaction";

const imageMap: Record<TransactionStates, ReactNode> = {
  processing: <img style={{ height: 40 }} src={ProcessingIcon} />,
  onRampCompleted: <img style={{ height: 40 }} src={ProcessingIcon} />,
  delayed: <img style={{ height: 40 }} src={PendingIcon} />,
  failed: <img style={{ height: 40 }} src={FailedIcon} />,
  completed: <img style={{ height: 40 }} src={SuccessIcon} />,
};

const defaultMessageMap = {
  processing: {
    title: "Processing your payment...",
    time: "This may take upto 5 min ",
    subtitle: "We will let you know once completed",
  },
  onRampCompleted: {
    title: "We have received your funds",
    time: "This may take upto 5 min ",
    subtitle: "You purchase is in progress",
  },
  delayed: {
    title: "Processing your payment",
    time: "This may take upto 5 min ",
    subtitle: "It is taking longer than expected",
  },
  failed: {
    title: "Payment failed",
    time: "This may take upto 5 min ",
    subtitle: "We were not able to find your payment",
  },
  completed: {
    title: "Payment successful",
    time: "This may take upto 5 min ",
    subtitle: "You have purchased the reward",
  },
};

type TransactionDetailProps = {
  transactionId: string;
  external: boolean;
  onClose: () => void;
};

export const TransactionDetail: FC<TransactionDetailProps> = ({
  transactionId,
  external,
  onClose,
}) => {
  const [txn, setTxn] = useState<Transaction | null>(null);

  const getTransaction = async () => {
    try {
      const result = external
        ? await getOnRampWithdrawTransaction({
          init: undefined,
          id: transactionId,
        })
        : await getOnRampTransaction({
          id: transactionId,
        });
      if (result?.success) {
        setTxn(plainToInstance(Transaction, result!.data as object));
      }
    } catch (e) { }
  };

  useEffect(() => {
    getTransaction();
    const poller = setInterval(() => {
      getTransaction();
    }, 5000);
    return () => clearInterval(poller);
  }, [transactionId]);

  const configContext: any = useContext(ConfigContext);
  let sla = configContext?.config?.sla;

  let methodSla = sla?.[txn?.paymentMethod ?? ""] ?? 600;
  let paymentState: TransactionStates = "processing";

  if (txn == null) {
    return <LoadingView />;
  }

  switch (txn.status) {
    case Status.ONRAMP_INITIATED:
      if (Date.now() > txn.createdAt.getDate() + methodSla * 1000) {
        paymentState = "delayed";
      } else {
        paymentState = "processing";
      }
      break;
    case Status.PROCESSING:
      paymentState = "processing";
      break;
    case Status.FAILED:
      paymentState = "failed";
      break;
    case Status.COMPLETED:
      paymentState = "completed";
      break;
  }

  const messageMap =
    configContext?.config?.messages?.onramp ?? defaultMessageMap;

  return (
    <Stack
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <div
        style={{
          paddingBottom: 16,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ flex: 1 }}>
        <TransactionStatus
          paymentState={paymentState as TransactionStates}
          message={messageMap[paymentState]}
          createdAt={txn.createdAt}
          amount={txn.cryptoAmount}
          paymentMethod={txn.paymentMethod ?? ""}
          support={configContext?.config?.messages?.support}
          currencySymbol={txn.cryptoSymbol}
          image={imageMap[paymentState]}
        />
      </div>
    </Stack>
  );
  {
    /* <div
        style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            flex: 1
        }}
    > */
  }
};

type TransactionStates =
  | "processing"
  | "delayed"
  | "failed"
  | "completed"
  | "onRampCompleted";

interface TransactionStatusProps {
  paymentState: TransactionStates;
  message: Message;
  createdAt: Date | string;
  amount: number;
  paymentMethod: string;
  support: any;
  image: ReactNode;
  currencySymbol?: string;
  item?: any;
  state?: TransactionStates
  onClickCancel?: () => void
}

type Message = {
  title: string;
  time: string;
  subtitle: string;
};

export const TransactionStatus: FC<TransactionStatusProps> = ({
  message,
  amount,
  paymentMethod,
  support,
  image,
  createdAt,
  currencySymbol,
  item,
  state,
  onClickCancel
}) => (
  <div
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      height: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
          {image}
          <Typography
            sx={{ fontSize: 16 }}
            style={{ fontWeight: 700, paddingTop: 5 }}
          >
            {message.title}
          </Typography>
          <Typography
            sx={{ fontSize: 14 }}
            style={{ fontWeight: 400, paddingTop: 3, paddingBottom: 3 }}
          >
            {message.time}
          </Typography>
          <Typography
            sx={{ fontSize: 13 }}
            style={{
              fontWeight: 400,
              paddingTop: 2,
              color: colors.Grey.title,
            }}
          >
            {message.subtitle}
          </Typography>
        </div>
      </div>
      <div
        style={{
          height: 8,
          marginTop: 15,
          marginBottom: 15,
          width: "100%",
          background: "#F5F5F5",
          borderRadius: 2,
        }} />
      <div>
        <div>
          <MRow
            leftElement={<Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Transaction summary
            </Typography>}
            rightElement={undefined} />
        </div>
        {item && (
          <div style={{ marginTop: 10 }}>
            <MRow
              leftElement={<Typography
                sx={{
                  fontSize: 14,
                  color: colors.Grey.title,
                  fontWeight: 400,
                }}
              >
                You are buying
              </Typography>}
              rightElement={<Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {item.substring(0, 30) + "..."}
              </Typography>} />
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          <MRow
            leftElement={<Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: colors.Grey.title,
              }}
            >
              Paid
            </Typography>}
            rightElement={<Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {`${currencySymbol ?? ""} ${amount}`}
            </Typography>} />
        </div>
        <div style={{ marginTop: 10 }}>
          <MRow
            leftElement={<Typography
              sx={{
                fontSize: 14,
                color: colors.Grey.title,
                fontWeight: 400,
              }}
            >
              Created At
            </Typography>}
            rightElement={<Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {createdAt instanceof Date
                ? moment(createdAt).format("MMM DD, yyyy • hh:mm")
                : createdAt}
            </Typography>} />
        </div>
        {paymentMethod && (
          <div style={{ marginTop: 10 }}>
            <MRow
              leftElement={<Typography
                sx={{
                  fontSize: 14,
                  color: colors.Grey.title,
                  fontWeight: 400,
                }}
              >
                Paid via
              </Typography>}
              rightElement={<Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {paymentMethod}
              </Typography>} />
          </div>
        )}
      </div>
      <div
        style={{
          height: 8,
          marginTop: 15,
          marginBottom: 15,
          width: "100%",
          background: "#F5F5F5",
          borderRadius: 2,
        }} />
    </div>
    <div style={{ cursor: "pointer" }}>
      {support && (
        <div
          style={{
            marginTop: 32,
            marginBottom: 16,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
            background: "#F5F5F5",
            borderRadius: 8,
          }}
          onClick={() => {
            window?.open(support.url)?.focus();
          }}
        >
          <MRow
            rightElement={<img src={rightArrow} />}
            leftElement={<div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
              }}
            >
              <img src={infoIcon} style={{ height: 14 }} />
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {support.title}
              </Typography>
            </div>} />
        </div>
      )}
      {/* {onClickCancel && state == "processing" && <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button color="error" variant="text" onClick={onClickCancel}>Cancel Payment</Button>
      </div>} */}
    </div>
  </div>
);
