import { useEffect, useMemo } from 'react';
import { FiatConversionWithCountry } from 'types/country';
import useInputValue, { InputValue } from 'types/input-value';

export type FiatCurrencyState = {
    fiatCurrency: InputValue<FiatConversionWithCountry> | null
    currencyList: FiatConversionWithCountry[]
}

const supportedCurrencies = [
    "INR", "EUR", "GBP"
]

function currencies(currencies: Array<FiatConversionWithCountry>): Array<FiatConversionWithCountry> {
    return currencies.filter((currency) => supportedCurrencies.includes(currency.symbol))
        .map((currency: FiatConversionWithCountry): FiatConversionWithCountry => {
            return {
                ...currency,
                iconId: currency.countries[0]?.substring(0, 2),
                ...(currency.symbol === "EUR" && { iconId: "EU" }),
                ...(currency.symbol === "GBP" && { iconId: "GB" }),
            }
        })
}

const useFiatCurrencyState = (currencyList: Array<FiatConversionWithCountry>, userCountry?: string, presetSymbol?: string, suggestedSymbol?: string): FiatCurrencyState => {

    const filteredCurrency = useMemo(() => currencies(currencyList), [currencyList]);

    const symbol = presetSymbol ?? suggestedSymbol
    const fixedPreset = Boolean(presetSymbol)
    const fiatCurrencySymbol = useInputValue(symbol, "EUR", false);
    let presetCurrency = useMemo(() => filteredCurrency?.find((currency) => currency.symbol === fiatCurrencySymbol.preset), [filteredCurrency, fiatCurrencySymbol.preset]);
    let selectedCurrency = useMemo(() => filteredCurrency?.find((currency) => currency.symbol === fiatCurrencySymbol.value), [filteredCurrency, fiatCurrencySymbol.value]);

    useEffect(() => {
        if (userCountry) {
            let userCountryCurrency = filteredCurrency.find((currency) => currency.countries.includes(userCountry))
            if (userCountryCurrency) {
                fiatCurrencySymbol.setValue!(userCountryCurrency.symbol);
            }
        }
    }, [userCountry, filteredCurrency])

    let setValue: ((value: FiatConversionWithCountry) => void) | null = null
    if (fiatCurrencySymbol.setValue && !userCountry) {
        setValue = ((value: FiatConversionWithCountry) => fiatCurrencySymbol.setValue!(value.symbol))
    }

    let fiatCurrency: InputValue<FiatConversionWithCountry> | null = null
    if (selectedCurrency) {
        fiatCurrency = {
            value: selectedCurrency,
            setValue: setValue,
            preset: presetCurrency ?? null,
            locked: fiatCurrencySymbol.locked,
            setLocked: fiatCurrencySymbol.setLocked,
            fixedPreset: fixedPreset
        }
    }
    return { currencyList: filteredCurrency, fiatCurrency };
};

export default useFiatCurrencyState;