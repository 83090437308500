import { TransactionDetail } from 'components/profile/transactions/transaction-detail';
import BaseLayout from 'components/shared/BaseLayout';
import MButton from 'components/shared/MButton';
import { ONRAMP_HOME } from 'constants/constants';
import { ConfigContext } from 'context/ConfigContext';
import React from 'react';
import { useParams } from 'react-router-dom';


const OnrampAndWithdrawStatus: React.FC = () => {
    const params = useParams();
    const transactionId = params["transaction_id"];

    const onClose = () => {
        window.location.href = ONRAMP_HOME
    }

    return (
        <ConfigContext.Provider
            // @ts-ignore
            value={{}}

        >
            <BaseLayout body={
                <TransactionDetail
                    transactionId={transactionId!}
                    external={true}
                    onClose={onClose}
                />
            } footer={
                <MButton
                    text={"Go to Home"}
                    onPress={onClose}
                    outlineButton={undefined} />}
                bottomSheet={undefined}
                // @ts-ignore
                isPurpleButton
                size="medium"

            />
        </ConfigContext.Provider>

    );
};


export default OnrampAndWithdrawStatus;