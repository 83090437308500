import { AuthContextProvider } from "context/auth/issuer-context";
import OnrampAndWithdrawStatus from "onramp/OnrampAndWithdrawStatus";
import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom";
import AuthorizedDigilocker from "routes/kyc/digilocker";
import "./App.css";
import Kyc from "./routes/kyc";
import OffRamp from "./routes/offramp";
import OnRamp from "./routes/onramp";
import Success from "./routes/success";
import { WithAuthentication } from "context/auth/use-auth";
import Digilocker from "routes/kyc/digilocker";

const AuthenticatedRoutes = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const signature = searchParams.get("secret");
  const timestamp = searchParams.get("timestamp");
  const userId = searchParams.get("user_id");
  const clientId = searchParams.get("client_id");
  const mode = "DEV";
  const hv_token = searchParams.get("hv_token");

  let init;

  if (signature && timestamp && userId && clientId && mode) {
    // console.log("AuthenticatedRoutes ~ signature", signature);
    init = async () => {
      return { signature, timestamp, userId, clientId, mode, hv_token };
    };
  }

  return (
    <AuthContextProvider init={init} clientId={searchParams.get("client_id")}>
      <Routes>
        <Route path="/kyc" element={<Kyc />} />
        <Route path="/kyc/digilocker" element={<Digilocker/>}/>
        <Route path="/offramp" element={<OffRamp />} />
        <Route path="/onramp" element={<OnRamp />} />
        <Route path="/success" element={<Success />} />
        <Route
          path="/transaction/w/buy/:transaction_id"
          element={<OnrampAndWithdrawStatus />}
        />
      </Routes>
    </AuthContextProvider>
  );
};

function App() {
  // const searchParams = new URL(document.location).searchParams;

  return (
    <div className="App">
      <BrowserRouter>
        <AuthenticatedRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
