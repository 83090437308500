import { useMemo, useState } from "react"

export interface BackStack<T> {
    current: T
    isTop: boolean
    goTo(state: T, isCheckPoint: boolean): void
    goBack(): boolean
    storeCache(): void
    reset(): void
}

export function useBackStack<T>(key: string, def: T): BackStack<T> {

    const storedValue = useMemo(() => {
        const cacheValue = JSON.parse(window.sessionStorage.getItem("backstack_" + key) ?? "null") ?? null
        window.sessionStorage.removeItem("backstack_" + key)
        return cacheValue
    }, [])

    const [stack, setStack] = useState<Array<T>>(storedValue ?? [def])

    const isTop = stack.length == 1
    const current = stack[stack.length - 1]
    const goTo = (state: T, isCheckPoint: boolean = false) => {
        console.log("goTo ~ state=>", state)

        let activeStack: Array<T>
        if (isCheckPoint) {
            activeStack = [state]
        } else {
            activeStack = [...stack, state]
        }
        console.log("goTo ~ activeStack=>", activeStack)
        setStack(activeStack)
    }

    const goBack = () => {
        if (!isTop) {
            setStack(stack.slice(0, -1))
            return true
        }
        return false
    }

    const storeCache = () => {
        window.sessionStorage.setItem("backstack_" + key, JSON.stringify(stack))
    }

    const reset = () => {
        setStack([def])
    }

    return {
        current,
        isTop,
        goTo,
        goBack,
        storeCache,
        reset
    }
}