import { Alert, Button, Typography } from "@mui/material";
import CopyToClipboard from "assets/svgs/copytoclipboard.svg";
import BaseLayout from "components/shared/BaseLayout";
import MCheckBox from "components/shared/CheckBox";
import MLink from "components/shared/Link";
import MButton from "components/shared/MButton";
import MHeading from "components/shared/MHeading";
import MRow from "components/shared/MRow";
import { ConfigContext } from "context/ConfigContext";
import { useToast } from "hooks/useToast";
import { useContext, useState } from "react";
import colors from "styles/colors.js";
import { useThemeHook } from "styles/themeMudrex";
import { EventLevel, sendEvent } from "utils/events/handler";
import NoteBottomsheet from "../NoteBottomsheet";
import UTRBottomsheet from "../UTRBottomsheet";
import styles from "./styles";

const UPITransferMethod = ({
  init,
  amount,
  handleCopy,
  handleTransfer,
  accountDetails,
  loading,
  error,
  selectedCurrency,
}) => {
  const toast = useToast();
  const classes = styles();
  const [theme] = useThemeHook();
  const [codeCopied, setCodeCopied] = useState(false);
  const [idCopied, setIdCopied] = useState(false);
  const configContext = useContext(ConfigContext);
  const themeObject = configContext.config.theme;
  const [isChecked, setIsChecked] = useState(false);
  const [showUtrBottomsheet, setUtrShowBottomsheet] = useState(false);
  const [showNoteBottomsheet, setNoteShowBottomsheet] = useState(false);
  const [utr, setUtr] = useState("");
  return (
    <BaseLayout
      theme={"primary"}
      headerTitle={"Pay via UPI apps"}
      headerVisible={true}
      body={
        <div>
          <MHeading
            heading={`Transfer ${selectedCurrency?.currencySymbol}${amount} to UPI ID`}
          />
          <div id="top-container" style={styles.topContainer}>
            <div
              style={{
                paddingHorizontal: 20,
                position: "relative",
              }}
            >
              <div style={theme.gradientBackgroundGrey}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        color: colors.Grey.title,
                        marginBottom: 3,
                      }}
                    >
                      UPI ID
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {accountDetails?.upi}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      setIdCopied(true);
                      handleCopy("upi");
                      toast.success(
                        "UPI ID Copied. Enter it in your UPI app for payment."
                      );
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={idCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: 16,
                    borderBottomWidth: 1,
                    borderColor: colors.Grey.borderGrey,
                    paddingBottom: 16,
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          color: colors.Grey.title,
                          marginBottom: 3,
                        }}
                      >
                        Message / Note
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#ffffff",
                          marginBottom: 3,
                          backgroundColor: "#C70055",
                          padding: "2px 5px",
                          fontSize: "0.6rem",
                          borderRadius: 2,
                        }}
                      >
                        <strong>Mandatory</strong>
                      </Typography>
                    </div>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      style={{ marginTop: 5 }}
                    >
                      {accountDetails?.code}
                    </Typography>
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    sx={{
                      textTransform: "none !important",
                    }}
                    onClick={() => {
                      setCodeCopied(true);
                      handleCopy("code");
                      toast.success(
                        "Unique ID Copied. Enter it as a message/ Note during payment."
                      );
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 4,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={CopyToClipboard} />
                      <MLink
                        bold={false}
                        text={codeCopied ? "Copied!" : "Copy"}
                      />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              <MLink
                text={"How do I add Note?"}
                direction="flex-start"
                fontSize={12}
                onClick={() => {
                  setNoteShowBottomsheet(true);
                }}
              />
            </div>
            {/* {themeObject && themeObject.palette.primary.main === "#2873F0" ? (
              <img
                style={{ marginTop: 10, width: "100%" }}
                src={UPIMessageFlip}
              />
            ) : (
              <img style={{ marginTop: 10, width: "100%" }} src={UPIMessage} />
            )} */}
            {error && (
              <div style={{ marginBottom: 10 }}>
                <Alert severity="error">
                  <Typography variant="alert">{error}</Typography>
                </Alert>
              </div>
            )}
          </div>
        </div>
      }
      footer={
        <div className={classes.transferredButtonContainer}>
          <MRow
            leftElement={
              <div
                style={{
                  flex: 1,
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 10,
                  gap: 5,
                }}
              >
                <MCheckBox
                  isChecked={isChecked}
                  size={"26"}
                  onClick={() => {
                    setIsChecked(!isChecked);
                  }}
                />
                <Typography fontSize={12}>
                  {"I entered the note while paying"}
                </Typography>
              </div>
            }
          />
          <MButton
            onPress={() => {
              handleTransfer()
            }}
            text={`I have transferred ${selectedCurrency?.currencySymbol}${amount}`}
            isPurpleButton
            size="medium"
            loading={loading}
            disabled={loading || !isChecked}
          />
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: 10,
            }}
          >
            <MLink
              onClick={() => {
                setUtrShowBottomsheet(true);
              }}
              fontSize={12}
              text={"Forgot to enter note?"}
            />
          </div>
        </div>
      }
      bottomSheet={
        <>
          <UTRBottomsheet
            init={init}
            isVisible={showUtrBottomsheet}
            onClose={() => setUtrShowBottomsheet(false)}
            createOrder={handleTransfer}
          />
          <NoteBottomsheet
            isVisible={showNoteBottomsheet}
            onClose={() => setNoteShowBottomsheet(false)}
          />
        </>
      }
    />
  );
};

export default UPITransferMethod;
