import { Typography } from '@mui/material';
import { useGetTransactions } from 'api/profile/useGetTransactions';
import emptyHistory from "assets/images/empty-history.png";
import { TransactionDetail } from 'components/profile/transactions/transaction-detail';
import TransactionListItem from 'components/profile/transactions/transaction-list-item';
import BaseLayout from 'components/shared/BaseLayout';
import { LoadingView } from 'components/shared/LoadingScreen';
import { FC, ReactElement, useState } from 'react';
import { Transaction, TransactionType } from 'types/transaction';

interface TransactionListProps {
}

export const TransactionList: FC<TransactionListProps> = (props) => {
  const { } = props;

  const { transactions, isFetching, errors } = useGetTransactions()
  const [transaction, setTransaction] = useState<Transaction | null>(null)
  const isEmpty = transactions.length == 0

  const onClick = (transaction: Transaction | null) => {
    setTransaction(transaction)
  }

  const onClose = () => {
    setTransaction(null)
  }

  let body: ReactElement

  if (transaction != null) {
    body = <TransactionDetail
      transactionId={transaction.id} 
      external={transaction.type === "EXTERNAL_BUY" || transaction.type === "ONRAMP_AND_WITHDRAW" }
      onClose={onClose} />
  } else if (isFetching) {
    body = <LoadingView />
  } else if (transactions.length !== 0) {
    body = <>{transactions.map((txn) =>
      <TransactionListItem
        key={txn.id}
        transaction={txn}
        onClick={onClick}
      />)}</>
  } else {
    body = <div style={{
      flex: 1, display: "flex", flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}>

      <img
        alt="no transactions found"
        style={{
          width: 100,
          height: 100,
          borderWidth: 1,
          // borderColor: colors.Grey.borderGrey,
          borderRadius: 6,
        }}
        src={emptyHistory}
      />
      <Typography variant="body1" sx={{ marginTop: 2 }}>No transactions found!</Typography></div>
  }

  return (
    <BaseLayout
      headerTitle={"Transactions"}
      headerVisible={true}
      body={
        <div id="body" style={{ height: "100%", width: "100%" }}>
          {body}
        </div>
      } footer={undefined} bottomSheet={undefined} />
  );
};

TransactionList.propTypes = {
};