import { createOrUpdateTransaction } from "api/OnRamp/createOrUpdateTxn";
import ChoosePaymentsMethod from "components/onRamp/ChoosePaymentsMethod";
import InvalidPaymentMethods from "components/onRamp/InvalidPaymentMethods";
import { useOnrampContext } from "context/OnRamp";
import { useEffect, useState } from "react";
import { OnrampType } from "types/OnRamp";
import { EventLevel, sendEvent } from "utils/events/handler";
import StateEnum from "../states";

const ChoosePaymentsMethodContainer = ({
  isLoadingMethods,
  setIsLoadingMethods,
  withdrawAllowedOnSelectedNetwork,
  selectedCurrency,
  paymentMethods,
  setCurrentState,
  fiatAmount,
  bankId,
  isKycPending,
  external,
  fromAmount,
  toAmount,
  externalNetwork,
  walletAddress,
  transactionId,
  orderId,
  setPaymentDetails,
  priceLock
}) => {

  const [loading, setLoading] = useState(false);

  const { cryptoCurrencyState, paymentMethodValidation, selectedPaymentMethod: spm } = useOnrampContext()
  const selectedPaymentMethod = spm.value
  const cryptoCurrency = cryptoCurrencyState.value

  const handleTransfer = async () => {

    const buyTransaction = await createOrUpdateTransaction(
      external ? OnrampType.ONRAMP_AND_WITHDRAW : OnrampType.ONRAMP,
      priceLock,
      bankId,
      selectedCurrency?.symbol,
      cryptoCurrency?.symbol,
      fromAmount,
      toAmount,
      selectedPaymentMethod,
      externalNetwork,
      walletAddress,
      undefined,
      undefined,
      orderId,
      transactionId
    )

    const eventName = orderId ? "ORDER_UPDATED" : "ORDER_CREATED"
    sendEvent(eventName, EventLevel.INFO, { order_id: orderId ?? buyTransaction.data?.id })

    setPaymentDetails(buyTransaction.data);

    return buyTransaction?.data?.id;
  };

  const navigate = (selectedPaymentMethod) => {
    const methodToStateMap = {
      "upi_transfer": StateEnum.UPI_PAYMENT_METHOD,
      "gpay_transfer": StateEnum.UPI_PAYMENT_METHOD,
      "paytm_transfer": StateEnum.UPI_PAYMENT_METHOD,
      "phonepe_transfer": StateEnum.UPI_PAYMENT_METHOD,
      "gpay_intent": StateEnum.INTENT_PAYMENT_METHOD,
      "paytm_intent": StateEnum.INTENT_PAYMENT_METHOD,
      "phonepe_intent": StateEnum.INTENT_PAYMENT_METHOD,
      "other_upi_intent": StateEnum.INTENT_PAYMENT_METHOD,
      "bank_transfer": StateEnum.BANK_PAYMENT_METHOD,
      "sepa_transfer": StateEnum.BANK_PAYMENT_METHOD,
      "van_transfer": StateEnum.VAN_PAYMENT_METHOD,
      "card_transfer": StateEnum.PAYMENT_METHOD_CHECKOUT
    };
  
    const newState = methodToStateMap[selectedPaymentMethod];
    if (newState) {
      spm.setValue(selectedPaymentMethod)
      setCurrentState(newState, true);
    }
  };
  
  const checkForDefaultPaymentMethod = (methods) => {
    const enabledMethods = Object.keys(methods).filter(method => methods[method].enabled);
  
    if (enabledMethods.length === 1) {
      const [method] = enabledMethods;
      if (method === "BANK_TRANSFER") {
        setCurrentState(StateEnum.BANK_PAYMENT_METHOD, true);
      } else if (method === "UPI") {
        setCurrentState(StateEnum.INTENT_PAYMENT_METHOD, true);
      }
    }
  };  
  
  const onClickMap = (categoryName, methodName) => {
    let selectedMethod = methodName;
  
    if (categoryName === "UPI") {
      selectedMethod = methodName.includes("intent") && methodName !== "other_upi_intent"
        ? methodName
        : "upi_transfer";
    }

    navigate(selectedMethod);
  };
  

  useEffect(() => {
    if (paymentMethods) checkForDefaultPaymentMethod(paymentMethods);
  }, []);

  useEffect(() => {
    if (withdrawAllowedOnSelectedNetwork && selectedPaymentMethod && paymentMethodValidation?.isEnabled) {
      navigate(selectedPaymentMethod);
    }

    if (selectedPaymentMethod && priceLock && !selectedPaymentMethod.includes("intent")) {
        setLoading(true);
        handleTransfer() 
        setLoading(false);
    }

    setIsLoadingMethods(
      selectedPaymentMethod ? !paymentMethodValidation : !paymentMethods
    );
    
  }, [paymentMethods, paymentMethodValidation]);
  

  return (
  <>
    <ChoosePaymentsMethod
      methods={paymentMethods}
      isLoadingMethods={isLoadingMethods}
      setCurrentState={setCurrentState}
      fiatAmount={fiatAmount}
      onClickMap={onClickMap}
      isKycPending={isKycPending}
      loading={loading}
      error={null}
    />
    {(paymentMethodValidation) && <InvalidPaymentMethods showDrawer={!paymentMethodValidation.isValid || !paymentMethodValidation.isEnabled} />}
    </>
  )
};

export default ChoosePaymentsMethodContainer;
