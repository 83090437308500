import { Expose } from 'class-transformer';

class UpiOption {
  @Expose({ name: 'conversion_type' })
  conversionType: string = '';

  @Expose()
  name: string = '';

  @Expose({ name: 'display_name' })
  displayName: string = '';

  @Expose()
  description: string | null = null;

  @Expose()
  mode: string = '';

  @Expose()
  type: string = '';

  @Expose()
  priority: number = 0;

  @Expose({ name: 'input_upi_id' })
  inputUpiId: boolean = false;
}

class PaymentOption {
  @Expose()
  enabled: boolean = false;

  @Expose()
  options: UpiOption[] = [];

  @Expose({ name: 'category_name' })
  categoryName: string = '';

  @Expose({ name: 'category_display_name' })
  categoryDisplayName: string = '';

  @Expose({ name: 'default' })
  isDefault: boolean = false;
}

class PaymentMethodResponse {
  @Expose()
  UPI: PaymentOption = new PaymentOption();

  @Expose()
  CARD: PaymentOption = new PaymentOption();

  @Expose()
  VIRTUAL_ACCOUNT: PaymentOption = new PaymentOption();
}

export default PaymentMethodResponse;
